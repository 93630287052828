import React from 'react'
import Position from '../../../components/Position';
import Highlight from '../../../components/Highlight';
import {
    Row,
    Space,
    Center,
    Container,
    TwoColumns,
    InfoContainer,
    ContainerFluid,
    ImageContainer
} from '../../../GlobalStyles';
import { StaticImage } from "gatsby-plugin-image";

import useI18n from '../../../i18n/useI18n';

const Description = ({ location }) => {

    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'What do',
        es: '¿Qué es'
    }    
    const title = {
        en: 'you want to know?',
        es: 'lo que quieres saber?'
    }
    
    return (
        <ContainerFluid>
            <Container>
                <Space />
                <Position location={location} />
                <Center>
                    <h2 data-sal="slide-up" data-sal-easing="ease-in-circ">
                        <Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}
                    </h2>
                    <p data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-delay=".2s">
                        {translateObject({
                            en: `The best way to find out what your customers think about the food and service 
                            they received is through a survey. With this tool you can create surveys according to your needs.`,
                            es: `La mejor manera de saber lo que tus clientes piensan sobre la comida y el servicio es que ellos llenen 
                            una encuesta. Con esta herramienta tu podrás crear encuestas según tus necesidades.`
                        })}
                    </p>
                </Center>
            </Container>
            <TwoColumns>
                <Row>
                    <Container className="fluid">
                        <InfoContainer>
                            <div data-sal='slide-left' data-sal-easing="ease-in-circ">
                                <h3>
                                    {translateObject({
                                        en: `Many type of questions`,
                                        es: `Diferentes tipos de preguntas`
                                    })}                                    
                                </h3>
                                <p>
                                    {translateObject({
                                        en: `Multiple choice? Star rating? Open questions? 
                                        We have different options available for you to create your survey 
                                        with all the information you want to obtain from your customers.`,
                                        es: `¿Opciones múltiples?, ¿Calificar con estrellas?, ¿Preguntas abiertas?
                                        Nosotros tenemos muchas opciones para que crees tu encuesta con toda la información
                                        que desees obtener de tus clientes.`
                                    })}
                                </p>
                            </div>

                            <div data-sal='slide-left' data-sal-easing="ease-in-circ" data-sal-delay='300'>
                                <h3>
                                    {translateObject({
                                        en: `Turn on and turn off anytime`,
                                        es: `Actívalas o desactívalas cuándo desees`
                                    })}
                                    
                                </h3>
                                <p>
                                    {translateObject({
                                        en: `You can create all the surveys you need and choose which one will be active 
                                        in each of your business units respectively. Modify your active or inactive status at any time that is required.`,
                                        es: `Puedes crear todas las encuentas que necesites y elegir cuál estará activa y para cuál de tus negocios.
                                        Activa o desactiva las encuestas en el momento en que lo deses.`
                                    })}                                    
                                </p>
                            </div>
                        </InfoContainer>
                    </Container>
                </Row>
                <ImageContainer data-sal='slide-right' data-sal-easing="ease-in-circ" data-sal-duration='1000'>
                    <StaticImage
                        src="../../../images/surveycreator-1.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="surveycreator-1"
                        className='mask-container'
                        imgClassName='img-fluid'
                    />
                </ImageContainer>
            </TwoColumns>
        </ContainerFluid>
    )
};

export default Description;