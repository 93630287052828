import React from 'react'
import Highlight from '../../../components/Highlight';
import { StaticImage } from "gatsby-plugin-image";
import { ThreeColumns } from '../../../GlobalStyles';

import useI18n from '../../../i18n/useI18n';

const SecondSection = () => {

    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'Customize',
        es: 'Personaliza'
    }    
    const title = {
        en: 'the questions how you wish',
        es: 'las preguntas cómo lo desees'
    }
    
    return (
        <ThreeColumns  className='fluid'>
            <div className='col-1'data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-duration='700'>
                <StaticImage
                    src="../../../images/surveycreator-2.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="surveycreator-2"
                    className="img-container overflow-fluid-md"
                    imgClassName="overflowed-img"
                />
            </div>

            <div className='center'data-sal="slide-up" data-sal-easing="ease-in-circ">
                <h2><Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}</h2>
                <p>
                    {translateObject({
                        en: `Showing the identity of your business at all times is important 
                        to have a brand presence, continue to do so when your customers fill out your surveys, 
                        which can be personalized as you wish through simple options.`,
                        es: `Es importante mantener tu identidad de marca, 
                        por eso podrás personalizar tus encuestas para que tus clientes sepan a quién pertencen al llenarlas.`
                    })}                    
                </p>
            </div>

            <div className='col-3'data-sal='slide-left' data-sal-easing="ease-in-circ" data-sal-duration='700'>
                <StaticImage
                    src="../../../images/surveycreator-3.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="surveycreator-3"
                    className='img-container'
                    imgClassName='overflowed-img'
                />
            </div>
        </ThreeColumns>
    )
};

export default SecondSection;