import React from "react";
import Seo from "../components/seo";
import ProductLayout from "../containers/ProductLayout";
import { Section } from '../GlobalStyles.jsx';
import { BG_DARK, BG } from '@etiquette-ui/colors';
import { graphql, useStaticQuery } from "gatsby";
import Description from "../sections/Products/SurveyCreator/description";
import SecondSection from "../sections/Products/SurveyCreator/second-section";
import '../globalStyles.css';

const SurveyCreator = () => {
  const dataquery = useStaticQuery(query);
  const datainfo = dataquery.allFile.edges;

  const data = {
    currentPage: 7,
    title: 'Survey Creator',
    heroLogo: datainfo[0].node.childrenImageSharp[0].fluid,
    heroAltLogo: datainfo[0].node.base,
    heroImage: datainfo[3].node.childrenImageSharp[0].fluid,
    heroAlt: datainfo[3].node.base,
    heroMobile: datainfo[3].node.childrenImageSharp[0].fluid,
    heroAltMobile: datainfo[3].node.base,
    banerImg: datainfo[4].node.childrenImageSharp[0].fluid,
    banerAlt: datainfo[4].node.base,
    banerImgMobile: datainfo[4].node.childrenImageSharp[0].fluid,
    banerImgAltMobile: datainfo[4].node.base,
    features: [
      {
        en: 'Easily create personalized surveys for your clients',
        es: 'Crea y personaliza facilmente encuestas para tus clientes'
      },
      {
        en: 'Have different surveys applied at the same time in different restaurants',
        es: 'Aplica encuesta diferentes al mismo tiempo desde cualquier restaurant o lugar'
      },
      {
        en: 'Create and manage templates to use them whenever you want',
        es: 'Crea y administra plantillas dónde sea que estés'
      },
      {
        en: 'Create surveys in different languages ​​as required',
        es: 'Crea encuestas en diferentes idiomas según tus necesidades'
      },
      {
        en: 'Customize the design of your surveys according to the look & feel of your brand',
        es: 'Personaliza el diseño de tus encuestas según tu marca'
      },
      {
        en: 'Have at your disposal 10 different types of questions to develop your survey',
        es: 'Existen diez diferentes tipos de preguntas para diseñar tus encuestas'
      },
      {
        en: 'Send all the information generated to the Dashboard for a better analysis and control',
        es: 'Envía toda la información a Dashboard para obtener un mejor análisis'
      }
    ],
  };

  return (
    <ProductLayout data={data} >
      <Seo title={data.title} />

      <Section color={BG}>
        <Description location={data.title} />
      </Section>
      <Section color={BG_DARK}>
        <SecondSection />
      </Section>
    </ProductLayout >
  );
};
export default SurveyCreator;

const query = graphql`
query surveyCreator {
    allFile(
      filter: {extension: {regex: "/(png)/"}, 
        name: {in: [
          "surveycreator-hero",
          "surveycreator-icon",
          "surveycreator-banner",
          "digitalmenu-hero-mobile",
          "digitalmenu-banner-mobile"
        ]}}
    ) {
      edges {
        node {
          base
          childrenImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }  
`;